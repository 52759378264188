import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'studentmeeting'

const GetAll = (data, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(data, pagination) 
})

const GetAllClassMeeting = teacherId => SocialProjectManager.get(`${resourceName}/teacher/${teacherId}`)

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const ExportExcelMeeting = criteria => SocialProjectManager.get(`${resourceName}/download-excel`, { params: criteria })

const StudentMeetingRepository = {
  GetAll,
  GetById,
  Create,
  Update,
  Delete,
  GetAllClassMeeting,
  ExportExcelMeeting
}

export default StudentMeetingRepository
