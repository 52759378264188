import GuidUtils from '@/shared/utils/guid'

export default class StudentMeeting {
  constructor({
    id,
    meetingDate,
    meetingName,
    meetingStatus,
    observation,
    responsible,
    teacherId,
    classId,
    companyId,
    studentsIds,
    className,
    teacherName,
    studentName
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.meetingDate = meetingDate,
    this.meetingName = meetingName,
    this.meetingStatus = meetingStatus,
    this.observation = observation,
    this.responsible = responsible,
    this.teacherId = teacherId,
    this.classId = classId,
    this.companyId = companyId
    this.studentsIds = studentsIds
    this.className = className
    this.teacherName = teacherName
    this.studentName = studentName
  }
}