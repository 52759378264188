import ClassRegistrationRepository from '@/shared/http/repositories/socialProject/classregistration'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import StudentMeetingRepository from '@/shared/http/repositories/socialProject/student-meeting'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import StudentMeetingCriteria from '@/shared/models/criteria/studentmeetingcriteria'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import StudentMeetingModel from '@/shared/models/studentMeeting'
import { dateFilter } from '@/shared/filters/ToDate'
import { uniqBy } from 'lodash'
import meetingEnum from '@/shared/enums/studentMeeting'
import download from 'downloadjs'
import Roles from '@/shared/mixins/hasRoles'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
  },

  filters: {
    meetingDateFormatted(value) {
      if (!value) return 'SEM DATA'
      const dateFormatted = dateFormattedFilter(value)
      return dateFormatted
    }
  },

  mixins: [Roles],

  data: () => ({
    criteria: new StudentMeetingCriteria(),
    studentMeetingModel: new StudentMeetingModel(),
    meetingOptions: Object.values(meetingEnum),
		optionsClass: [],
		optionsTeachers: [],
		students: [],
    studentsMeeting: [],
    optionsTeachersMeeting: [],
    optionsClassMeeting: [],
		emptyText: 'Nada para mostrar',
    emptyTextMeeting: 'Faça uma pesquisa',
    dateFormatted: null,
    dateFormattedMeeting: null,
    showModalMeeting: false,
    totalRegister: 0,
    id: null,
    selected: {},
    showModalDeleteMeeting: false,
    meetingNameAlreadySaved: [],
		fields: [
			{
				key: 'className',
				label: 'TURMA',
				class: 'left-header-border text-center left-header-border',
				sortable: true
			},
      {
				key: 'studentName',
				label: 'ALUNO',
				class: 'middle-header text-center',
				sortable: true
			},
      {
				key: 'contacts',
				label: 'RESPONSÁVEL',
				class: 'right-header-border text-center right-header-border',
				formatter: (value) => {
          if (!value) return 'NÃO CADASTRADO'

          const contact = uniqBy(value, 'name').map(x => x.name.toUpperCase())
          return contact.join(' | ')
        }
			}
		],
    fieldsMeeting: [
      {
        key: 'studentName',
        label: 'ALUNO',
        class: 'left-header-border text-center'
      },
      {
        key: 'meetingName',
        label: 'NOME - REUNIÃO',
        class: 'middle-header text-center'
      },
      {
        key: 'meetingDate',
        label: 'DATA DA REUNIÃO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter,
        sortable: true
      },
      {
        key: 'className',
        label: 'TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'meetingStatus',
        label: 'STATUS DA REUNIÃO',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Reunião ABDA',
            active: true
          }
        ]
      }
		},

    disable() {
      const hasValues = this.criteria.teacherId != null && this.criteria.classId != null
      
      if (hasValues) {
        return false
      }

      return true
    },

    disableMeetingBtn() {    
      const meetingDateNoHasValue = this.studentMeetingModel.meetingDate == null || this.studentMeetingModel.meetingDate == '-' || this.studentMeetingModel.meetingDate == 'Invalid Date' 
      const meetingNameNoHasValue = this.studentMeetingModel.meetingName == null || this.studentMeetingModel.meetingName.trim() == ''
      const studentsNoHasValue = this.students.length == 0

      if (meetingDateNoHasValue || meetingNameNoHasValue || studentsNoHasValue) return true

      return false
    },

    disableInputResponsible() {
      const diffConfirmed = this.studentMeetingModel.meetingStatus != 2
      if (diffConfirmed) {
        this.studentMeetingModel.responsible = null
        return true
      }
      return false
    }
	},

  created() {
		this.getAllTeacher()
    this.getAllMeeting(true)
  },

  methods: {
    onShowInputMeeting(row) {
      const rowMeetingSelected = row
      this.showModalMeeting = true

      this.studentMeetingModel = rowMeetingSelected
    },

    onCloseModalMeeting() {
      this.showModalMeeting = false
      this.studentMeetingModel = new StudentMeetingModel()
      this.getAllMeeting(false)
    },

    onCheckMeetingStatus(item, type) {
      if (!item || type !== 'row') return
      if (item.meetingStatus == 0) {
        return 'table-secondary'
      }
      
      if (item.meetingStatus == 1) {
        return 'table-danger'
      }

      if (item.meetingStatus == 2) {
        return 'table-success'
      }
    },

		getClass() {
      loading.push()
      ClassRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

		getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    LoadClassRegistration(classeId) {
      loading.push()
      ClassRegistrationRepository.GetByClassStudent(classeId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          
          this.students = res.data.data
          this.totalRegister = res.data.count

          const getStudentsId = this.onlyStudentsId(this.students)
          this.studentMeetingModel.studentsIds = getStudentsId

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar os alunos registrados', 'ERRO')
        })
    },

    saveMeeting() {
      loading.push()
      this.studentMeetingModel.classId = this.criteria.classId
      this.studentMeetingModel.teacherId = this.criteria.teacherId
      this.studentMeetingModel.meetingName = this.studentMeetingModel.meetingName.toUpperCase()

      const meetingNameAlreadyExist = this.cheeckMeetingNameIsAlreadySave(this.studentMeetingModel.meetingName)

      if (meetingNameAlreadyExist) {
        loading.pop()
        toast.info('Nome da reunião já cadastrado', 'NOME REPETIDO')
        return
      }

      StudentMeetingRepository.Create(this.studentMeetingModel)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Reunião agendada com sucesso!', 'AGENDANDO REUNIÃO')
          this.onClearTabs()
          this.getAllMeeting(true)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar a reunião', 'ERRO')
        })
    },

    getAllMeeting(onSearch) {
      if (this.criteria.meetingDate == '-' || this.criteria.meetingDate == 'Invalid Date') {
        this.criteria.meetingDate = null
      }

      loading.push()
      StudentMeetingRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (onSearch) {
            const response = res.data.data
            this.onOptionsTeachersMeeting(response)
            this.getAllMeetingName(response)
            
            loading.pop()
            return
          }

          this.studentsMeeting = res.data.data
          this.totalRegister = res.data.count

          if (this.studentsMeeting.length == 0) {
            toast.info('Não encontrei nenhuma informação', 'INFORMAÇÃO NÃO ENCONTRADA')
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'Erro ao pegar reuniões')
        })
    },

    getClassMeeting() {
      const teacherId = this.criteria.teacherId
      if (!teacherId) return

      loading.push()
      StudentMeetingRepository.GetAllClassMeeting(teacherId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const response = res.data.data

          const responseFormatted = uniqBy(response, 'classId') .map(x => ({
            name: x.className,
            id: x.classId
          }))

          this.optionsClassMeeting = responseFormatted
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

    onUpdateMeeting() {
      if (this.studentMeetingModel.meetingStatus == 2) {
        if (this.studentMeetingModel.responsible == null || this.studentMeetingModel.responsible.trim() == '') {
          toast.info('Preencha o nome do responsável presente', 'REUNIÃO')
          return
        }
      }

      loading.push()
      StudentMeetingRepository.Update(this.studentMeetingModel)
        .then(result => {
          if (!IsSuccessCode(result)) return Promise.reject()

          toast.success('Informações inseridas com sucesso', 'SUCESSO')
          this.studentMeetingModel = new StudentMeetingModel()
          this.showModalMeeting = false
          this.getAllMeeting(false)

          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível salvar registro.', 'ERRO')
          loading.pop()
        })
    },

    onDelete() {
      loading.push()
      StudentMeetingRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAllMeeting(false)
          this.showModalDeleteMeeting = false
          toast.success('Reunião excluída com sucesso!', 'EXCLUSÃO DE REUNIÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir a reunião', 'ERRO')
        })
    },

    downloadExcel() {
      const criteriaNoHasValue = this.validateCriteriaNoHasValue()
      if (criteriaNoHasValue) {
        toast.info('Preencha alguma informação antes de montar o relatório', 'RELATÓRIO DE REUNIÃO')
        return
      }

      if (this.criteria.meetingDate == '-' || this.criteria.meetingDate == 'Invalid Date') {
        this.criteria.meetingDate = null
      }

      loading.push()
      StudentMeetingRepository.ExportExcelMeeting(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const haveData = this.studentsMeeting.length
          if(haveData == 0) {
            toast.warning('Realize uma pesquisa com informações para depois gerar uma planilha', 'RELATÓRIO REUNIÃO')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    onlyStudentsId(item) {
      if (!item) return

      const ids = item.map(x => x.studentId)
      return ids
    },

    getAllMeetingName(item) {
      if (!item) return

      const meetingNames = item.map(x => x.meetingName)
      const meetingUniqNames = uniqBy(meetingNames)
      const filteredMeetingNames = []
      
      meetingUniqNames.forEach(element => {
        const noSpaceName = element.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '')
        filteredMeetingNames.push(noSpaceName)
      });

      this.meetingNameAlreadySaved = filteredMeetingNames
    },

    cheeckMeetingNameIsAlreadySave(currentMeetingName) {
      if (!currentMeetingName) return

      const formattedCurrentMeetingName = currentMeetingName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '')
      const isAlreadyExist = this.meetingNameAlreadySaved.some(x => x == formattedCurrentMeetingName )

      if (isAlreadyExist) return true

      return false
    },

    onOptionsTeachersMeeting(item) {
      const response = item
      const teacher = response.map(x => ({
        id: x.teacherId,
        name: x.teacherName
      }))
      const formattedTeacher = uniqBy(teacher, 'id')
      this.optionsTeachersMeeting = formattedTeacher
    },

    onClearTabs() {
      this.criteria = new StudentMeetingCriteria()
      this.studentMeetingModel = new StudentMeetingModel()
      this.students = []
      this.studentsMeeting = []
      this.totalRegister = 0
    },

    onGetAllMeeting() {
      const checkHasValue = this.validateCriteriaNoHasValue()
      if (checkHasValue) {
        toast.info('Preencha alguma informação antes de realizar a pesquisa', 'CONSULTAR REUNIÃO')
        this.studentsMeeting = []
        this.totalRegister = 0
        return
      }

      this.getAllMeeting(false)
    },

    validateCriteriaNoHasValue() {
      const meetingDateNoHasValue = this.criteria.meetingDate == null || this.criteria.meetingDate == '-' || this.criteria.meetingDate == 'Invalid Date' 
      const meetingNameNoHasValue = this.criteria.meetingName == null || this.criteria.meetingName.trim() == ''
      const classIdOrTeacherIdNoHasValue = this.criteria.classId == null && this.criteria.teacherId == null 
      const meetingStudentNameNoHasValue = this.criteria.studentName == null || this.criteria.studentName.trim() == ''
      const meetingStatusNoHasValue = this.criteria.meetingStatus == null

      if (meetingDateNoHasValue && meetingNameNoHasValue && classIdOrTeacherIdNoHasValue && meetingStatusNoHasValue && meetingStudentNameNoHasValue) {
        return true
      }
      return false
    },

    selectRow(item) {
      this.showModalDeleteMeeting = true
      this.selected = item
      this.id = item.id
    },

    onCloseDeleteModal() {
      this.showModalDeleteMeeting = false
      this.selected = {}
      this.id = null
    },
    
    backTo() { this.$router.go(-1) },

    onDateContext(ctx) {      
      this.dateFormatted = ctx.selectedFormatted
    },

    onDateContextMeeting(ctx) {      
      this.dateFormattedMeeting = ctx.selectedFormatted
    },

    formatDate(event) {
      this.studentMeetingModel.meetingDate = dateFilter(event.currentTarget.value)
    },

    formatDateMeeting(event) {
      this.criteria.meetingDate = dateFilter(event.currentTarget.value)
    },
  }
}
