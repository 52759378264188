const studentMeeting = {
  WITHOUTCONFIRMATION: { 
    id: 0, 
    name: 'Não Realizada' 
  },

  NOCONFIRMATION: { 
    id: 1, 
    name: 'Responsável Ausente' 
  },

  CONFIRMED: { 
    id: 2, 
    name: 'Responsável Presente' 
  },
}

Object.freeze(studentMeeting)

export default studentMeeting