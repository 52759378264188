export default class StudentMeetingCriteria {
    constructor({
      classId,
      teacherId,
      meetingName,
      meetingDate,
      meetingStatus,
      studentName
    } = {}) {
      this.classId = classId
      this.teacherId = teacherId
      this.meetingName = meetingName
      this.meetingDate = meetingDate
      this.meetingStatus = meetingStatus
      this.studentName = studentName
    }
  }